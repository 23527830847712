import request from '@/utils/axios';

export function fetchDashboardStats(data) {
  return request({
    url: '/dashboard/stats',
    method: 'GET',
    data: data,
  });
}

export function fetchDashboardCreateHistory(data) {
  return request({
    url: '/dashboard/create_history',
    method: 'GET',
    data: data,
  });
}

export function postFactoryComment(data) {
  return request({
    url: '/operationcomment',
    method: 'POST',
    data: data,
  });
}

export function postStoreComment(data) {
  return request({
    url: '/shopcomment',
    method: 'POST',
    data: data,
  });
}

export function fetchComments(param) {
  return request({
    url: '/comments?' + param,
    method: 'get'
  });
}

export function fetchRmalist(param) {
  if (param) {
    return request({
      url: '/rma/'+param,
      method: 'get',
      timeout: 40000,
    });
  }
  return request({
    url: '/rma',
    method: 'get',
    timeout: 40000,
  });
}

export function fetchRmalistForOperation(param) {
  if (param) {
    return request({
      url: '/operationrma'+param,
      method: 'get',
      timeout: 40000,
    });
  }
  return request({
    url: '/operationrma',
    method: 'get',
    timeout: 40000,
  });
}

export function fetchRmalistForOperationByPage(param) {
  if (param) {
    return request({
      url: '/rmalist?' + param,
      method: 'get',
      timeout: 40000,
    });
  }
  return request({
    url: '/rmalist',
    method: 'get',
    timeout: 40000,
  });
}

export function fetchRmaStatusList() {
  return request({
    url: '/rmastatuslist',
    method: 'get',
    timeout: 40000,
  });
}

export function fetchRmalistForOperationByStatus(param) {
  return request({
    url: '/operationrmabystatus/'+param,
    method: 'get',
    timeout: 40000,
  });
}

export function fetchRMASentToWarehouse() {
  return request({
    url: '/rmasenttowarehoure',
    method: 'get'
  });
}

export function fetchRMATransitToShop() {
  return request({
    url: '/rmaintransittoshop',
    method: 'get'
  });
}

export function fetchRmaReasons() {
  return request({
    url: '/rma_reason',
    method: 'get'
  });
}

export function fetchRmaRepairFees() {
  return request({
    url: '/repairfees',
    method: 'get'
  });
}

export function fetchRmaByTrackingCode(data) {
  return request({
    url: '/trackingrma',
    method: 'POST',
    data: data,
  });
}

export function storeRma(data) {
  return request({
    url: '/rma',
    method: 'POST',
    timeout: 40000,
    data: data,
  });
}

export function deliverType(data) {
  return request({
    url: '/check/deliver/type',
    method: 'POST',
    timeout: 40000,
    data: data,
  });
}

export function UpdateStatus(data) {
  return request({
    url: '/rmastatus',
    method: 'put',
    data: data,
  });
}

export function fetchRmaSavCheck(data) {
  return request({
    url: '/savcheck',
    method: 'POST',
    data: data,
  });
}

export function printSingleLabel(data) {
  return request({
    url: '/rma/label',
    method: 'post',
    data: data,
  });
}

export function printPackageLabel(data) {
  return request({
    url: '/rma/packagelist',
    method: 'post',
    data: data,
  });
}

export function getLateRma() {
  return request({
    url: '/rma/retailnotsend',
    method: 'get',
  });
}

export function getRmaInRepairs() {
  return request({
    url: '/rma/inrepair',
    method: 'get',
  });
}

export function generateExcelFile(data) {
  return request({
    url: '/excel',
    method: 'post',
    data: data,
  });
}

/**
 * 
 * RMA Worker Repository
 * 
 */

export function authRmaWorker(data) {
  return request({
    url: '/auth/rma_worker',
    method: 'post',
    data: data,
  });
}

export function getAllRmaWorkers(data) {
  return request({
    url: '/all/rma_workers',
    method: 'post',
    data: data,
  });
}

export function updateRmaWorkerApi(data) {
  
  // const x = {"id":data.id,"Department":data.Department,"WrokerID":data.WrokerID,"WorkerName":data.WorkerName,"IsActive":data.IsActive,"Barcode":data.Barcode,"Permission":data.Permission.join()}

  // if (data.Permission == ""){
  //   x.Permission = "-";
  // }
          
  //console.log(89,x);
  return request({
    url: '/update/rma_worker',
    method: 'post',
    data: data,
  });
}

export function createWorkerApi(data) {
  
  // const x = {"id":data.id,"Department":data.Department,"WrokerID":data.WrokerID,"WorkerName":data.WorkerName,"IsActive":data.IsActive,"Barcode":data.Barcode,"Permission":data.Permission.join()}
      
  // console.log(89,x);
  return request({
    url: '/create/rma_worker',
    method: 'post',
    data: data,
  });
}

export function deleteWorkerApi(data) {
  return request({
    url: '/delete/rma_worker',
    method: 'post',
    data: data,
  });
}

/**
 * 
 * RMA Worker Role Repository
 * 
 */

export function getAllWorkersRoleApi(data) {
  return request({
    url: '/all/rma_workers_role',
    method: 'post',
    data: data,
  });
}

export function updateWorkersRoleApi(data) {
  return request({
    url: '/update/rma_worker_role',
    method: 'post',
    data: data,
  });
}

export function createWorkersRoleApi(data) {
  return request({
    url: '/create/rma_worker_role',
    method: 'post',
    data: data,
  });
}

export function fetchCreateCancelCloselist() {
  return request({
    url: '/rmacreatecancelclose',
    method: 'get'  
  });
}

export function deleteWorkersRoleApi(data) {
  return request({
    url: '/delete/rma_worker_role',
    method: 'post',
    data: data,
  });
}

export function createSAVCheckApi(data) {
  return request({
    url: '/operationAddSav',
    method: 'post',
    data: data,
  });
}

export function fetchWsProduct(param) {
  return request({
    url: '/getwsproduct?' + param,
    method: 'get'
  });
}

export function fetchImportProductTypesense(param) {
  return request({
    url: '/importProduct',
    method: 'get'
  });
}

export function fetchPackageStatus(param) {
  return request({
    url: '/packagestatus?' + param,
    method: 'get'
  });
}

export function fetchStorePackage(data) {
  return request({
    url: '/storepackage',
    method: 'post',
    data: data
  });
}

export function fetchFactoryPackage(data) {
  return request({
    url: '/factorypackage',
    method: 'post',
    data: data
  });
}

export function fetchSendPackage(data) {
  return request({
    url: '/send_package',
    method: 'post',
    data: data
  });
}

export function fetchPackageprice(param) {
  return request({
    url: '/packageprice?' + param,
    method: 'get'
  });
}

export function getWorkersPermissionListApi() {
  return request({
    url: '/auth/permissionlist',
    method: 'get'
  });
}

export function fetchSaveSign(data) {
  return request({
    url: '/saveSign',
    method: 'post',
    data: data
  });
}

export function fetchGetSignature(data) {
  return request({
    url: '/getSignature?' + data,
    method: 'get',
    timeout: 40000,
  });
}

export function fetchGetCarrierList() {
  return request({
    url: '/carrier/list',
    method: 'get'
  });
}

export function fetchClearOrderStatus(param) {
  return request({
    url: '/tools/autoclientreceived?' + param,
    method: 'get'
  });
}

export function fetchCanTransfer(data) {
  return request({
    url: '/sav/can_transfer',
    method: 'post',
    data: data
  });
}

export function fetchTranferFactory(data) {
  return request({
    url: '/sav/tranfer_factory',
    method: 'post',
    data: data
  });
}

export function fetchNeedTranfer(data) {
  return request({
    url: '/sav/need_to_transfer',
    method: 'post',
    data: data
  });
}

export function fetchTranferList() {
  return request({
    url: '/sav/transfer_list',
    method: 'get'
  });
}

export function fetchTranferPackage() {
  return request({
    url: '/sav/transfer_package',
    method: 'get'
  });
}

export function fetchTranferClear() {
  return request({
    url: '/sav/transfer_clear',
    method: 'get'
  });
}

export function fetchRmaCountry() {
  return request({
    url: '/get_all_country',
    method: 'get'
  });
}

export function fetchRmaLanguage() {
  return request({
    url: '/get_all_language',
    method: 'get'
  });
}

export function fetchRejectReason(param) {
  return request({
    url: '/reject/reasons?user_type=' + param,
    method: 'get'
  });
}
// 获取黄金商品序列号信息
export function fetchProductCert(param) {
  return request({
    url: '/get_one_product_cert?cert_no=' + param,
    method: 'get'
  });
}
// 上传黄金称重图片接口
export function postUploadGoldImage(data) {
  return request({
    url: '/upload/img',
    method: 'POST',
    data: data,
  });
}
// 获取原因列表
export function fetchAllReasonsList() {
  return request({
    url: '/system/reason/list',
    method: 'GET',
  });
}
// 根据主原因获取子原因列表
export function fetchSubReasonsList(param) {
  return request({
    url: '/system/subreason/list?rma_reason_id=' + param,
    method: 'GET',
  });
}
// 获取子原因
export function fetchSubReason(param) {
  return request({
    url: '/system/subreason/get?id=' + param,
    method: 'GET',
  });
}
// 添加或修改子原因
export function postSubReason(data) {
  return request({
    url: '/system/subreason/create_or_update',
    method: 'POST',
    data
  });
}

// 上传假货信息
export function postFakeProduct(data) {
  return request({
    url: '/fake/product',
    method: 'POST',
    data
  });
}
// 查询购买记录
export function fetchHasPuchaseRecord(param) {
  return request({
    url: '/rma/has_puchase_record?rma_item_id=' + param,
    method: 'GET',
  });
}

// 更新快递单号
export function fetchUpdateTrackingNumber(data) {
  return request({
    url: '/sav/update_tracking_number',
    method: 'POST',
    data
  });
}

// 查询售后状态列表
export function fetchAfterSalesList(param) {
  return request({
    url: '/get_after_sale_list?rma_item_id=' + param,
    method: 'GET',
  });
}
// 更新售后状态
export function fetchUpdateAfterSales(data) {
  return request({
    url: '/add_after_sales',
    method: 'POST',
    data
  });
}

// 查询Boutique国家列表
export function fetchBoutiqueCountryList() {
  return request({
    url: '/international/countries',
    method: 'GET'
  });
}

// 查询国际运费列表
export function fetchShippingFeeList() {
  return request({
    url: '/international/shipping_fees',
    method: 'GET'
  });
}

// 校验发票号
export function fetchVerifyInvoice(param) {
  return request({
    url: '/verify_invoice?invoice_no=' + param,
    method: 'GET'
  });
}

// 国家城市列表
export function fetchCountryCityList(param) {
  return request({
    url: '/get_country_city_list?countries_id=' + param.countries_id,
    method: 'GET'
  });
}