export default {
  route: {
    Dashboard: '首页',
    operationDashboard: '首页',
    rmadetails: '維護',
    listRma: '列表',
    clientSearch: "客户查询",
    CreateRma: '建立',
    PreparePackage: '準備',
    ReceivedPackage: '接收',

    operationlistRma: '列表',

    RMAReadyToShip: "RMA待包裹发出",
    RMAReadyToShip72: "RMA待运输中（>72小时）",
    RMAWaitingForCustomer: "RMA待客确认",
    RMAWaitingForCustomer1week: "RMA待客确认（+1周）",
    RMASentToWarehouse: "RMA发送仓库",
    RMAIntransit: "RMA交互中",
    cityAndcountry: "城市和国家",

    Week: "周",
    Day: "天",
    TrackingCount: "状态追踪#",
    RMACount: "RMA维修#",

    Size: "尺码",
    Amount: "金额",
    Quantity: "数量",
    Image: "图像",
    RepairTime: '维修次数',
    viewBtn: '查 看',
    city: '城市',

    Create: "创建",
    Created: "已创建",
    SubmissionSuccess: "创建成功",
    ReturnToRMAList:"返回列表", 
    CreatedBy: "已创建的人",
    CreatedOn: "已创建时间",
    By: "修改人",
    confirmCustomerPickup: "确认客户收货",
    reject: "不批准",
    pleaseSelectAProduct: "请选择一个产品",
    pleaseSelectAnInvoice: "请选择一个单据编号",
    selectRepairReason: "选择维修原因",

    InStore: "到达店铺",
    TransitToFactory: "传送到工厂",
    InRepair:"维修中",
    TransitToStore:"传送到店铺",
    WaitingForPickUp:"待取件", 

    OrderDate: "采购订货时间",
    Date: "时间",
    CreateDate: "创建时间",
    RMA: "RMA维修",
    Tracking: "状态追踪",
    ShippingDate: "运输日期",

    Number: "编号",
    Name: "姓名",
    Phone: "电话",
    Status: "状态", 
    RMAStatus: "RMA的状态",
    DeleteRMA: "删除RMA",
    RMAInformation: "RMA的信息",
    blankRMA: "新RMA",
    province: "省市区",
    address: '详细地址',
    postalCode: '邮政编码',
    toCustomer: '至客户',
    DeliverToCustomer: '交付给客户',
    mailingInformation: "邮寄信息",
    receiverName: '收件人姓名',
    receiverPhone: '收件人电话',
    isAgent: '是否代收',
    agent: '代收',

    Searching:"搜索",
    CreateRMAForm: "创建RMA表格",
    Completed: "已完成",

    SearchingWithName : "搜索（姓名/邮箱/电话",
    SearchingWithTheInvoiceNumber : "搜索单据编号",

    FirstName: "名",
    LastName: "姓", 
    Email: "邮箱",
    Invoice: "凭证单据",
    InvoiceNo: "单据编号",

    Search: "搜索",
    Uploads: "上传",
    Uploaded: "已上传",
    images: "张",
    UploadTotalWeight: "上传总重",
    Warranty: "保修",
    noWarranty: "不保修",
    Expired: "已到期",
    UnderWarranty: "在保修期内",
    WarrantyDate: "保修截止日期",
    ExtendedWarranty: "是否延保",
    Reasons: "原因",
    ChooseFiles:"选择文件",
    Select: "选择",

    NoMatchesFound: "未找到匹配项", 

    RenewDetails: '选择维修原因（仅用于记录信息）',

    Contact:"联系方式",
    Item:"项目",
    Attachment:"附件",

    unscanned:"未扫描",
    scanned:"已扫描",

    Adding:"添加中",
    Pending:"暂缓",
    ClearAll: "全部清除",
    PreparePackage:"准备包裹",
    TrackingNumber:"追踪号",
    ScanYourRMAProduct:"扫描您的RMA货品",
    ScanYourTrackingNumber:"扫描您的追踪号",
    LatestProductScanned:"最近被扫描的货品",

    RedIsUnscannedProduct :"红色是未扫描货品",
    GreenIsScannedProduct :"绿色是已扫描货品",
    // operation
    Validate:"生效",
    GenerateExcel:"生成Excel",
    DownloadExcelTemplate: "下载Excel模板",

    RMAOperations: "RMA操作",
    RepairOperations: "维修操作",
    RepairProcess: "维修进度",
    PolishingProcess: "么打",
    PlatingProcess: "电镀",
    AssemblyProcess: "组装",
    QCFinal: "质检结果",
    QCProcess: "质检",
    Assembly: "组装",
    PenPlatingProcess: "笔电",
    SettingStone: "跟石",
    SettingPearl: "粘珠",
    Repaired: "维修完成",
    LogisticShipOut: "物流发出",
    MCToCN: "MC to CN",
    SwitchWorker: "转换工作人员",
    Worker: "工作人员", 
    StoreID: "店铺序号",
    StoreName: "店铺名称",

    WorkID: "工作人员序号",
    Barcode: "条码",
    Department: "部门",
    Role: "角色",
    IsActive: "激活",  
    Action: "行动",
    Active: "激活",
    InActive: "待激活", 
    Generate: "生成",
    WorkerRole: "工作角色",

    WorkerDepartment : "工作人员部门",
    WorkerName: "工作人员姓名",
    AddWorker: "新增工作人员",
    CreateANewWorker: "创建一个新工作人员",
    EditWorker: "更改工作人员",
    CreateANewRole: "创建一个新角色",
    EditRole: "更改角色",

    ListWorkers: "工作人员列表",
    WorkerRoles: "角色",

    introduction: '简述',
    documentation: '文档',
    guide: '引导页',
    permission: '权限测试页',
    rolePermission: '角色权限',
    pagePermission: '页面权限',
    directivePermission: '指令权限',
    icons: '图标',
    components: '组件',
    componentIndex: '介绍',
    tinymce: '富文本编辑器',
    markdown: 'Markdown',
    jsonEditor: 'JSON编辑器',
    dndList: '列表拖拽',
    splitPane: 'Splitpane',
    avatarUpload: '头像上传',
    dropzone: 'Dropzone',
    sticky: 'Sticky',
    countTo: 'CountTo',
    componentMixin: '小组件',
    backToTop: '返回顶部',
    dragDialog: '拖拽 Dialog',
    dragSelect: '拖拽 Select',
    dragKanban: '可拖拽看板',
    charts: '图表',
    keyboardChart: '键盘图表',
    lineChart: '折线图',
    mixChart: '混合图表',
    example: '综合实例',
    nested: '路由嵌套',
    menu1: '菜单1',
    'menu1-1': '菜单1-1',
    'menu1-2': '菜单1-2',
    'menu1-2-1': '菜单1-2-1',
    'menu1-2-2': '菜单1-2-2',
    'menu1-3': '菜单1-3',
    menu2: '菜单2',
    Table: 'Table',
    dynamicTable: '动态Table',
    dragTable: '拖拽Table',
    inlineEditTable: 'Table内编辑',
    complexTable: '综合Table',
    treeTable: '树形表格',
    customTreeTable: '自定义树表',
    tab: 'Tab',
    form: '表单',
    createArticle: '创建文章',
    editArticle: '编辑文章',
    articleList: '文章列表',
    errorPages: '错误页面',
    page401: '401',
    page404: '404',
    errorLog: '错误日志',
    excel: 'Excel',
    exportExcel: '导出 Excel',
    selectExcel: '导出 已选择项',
    mergeHeader: '导出 多级表头',
    uploadExcel: '上传 Excel',
    zip: 'Zip',
    pdf: 'PDF',
    exportZip: 'Export Zip',
    theme: '换肤',
    clipboardDemo: 'Clipboard',
    i18n: '国际化',
    externalLink: '外链',
    elementUi: "Element UI",
    administrator: "Administrator",
    users: "Users",
    userProfile: "User Profile",
    error: "错误",
    success: "成功",
    RMAHasBeenUpdated: "RMA已更新",
    RMAHasBeenCanceled: "RMA已取消",
    confirmCanceled: "确定要取消这张订单吗？",

    factoryRemark: "出厂备注",
    StoreRemark: "门店备注",
    updateRemark: "更新备注",
    confirmRemark: "确定提交此备注吗",
    RMAFactoryRemarkUpdated: "出厂备注已更新",
    RMAStoreRemarkUpdated: "门店备注已更新",
    InputFactoryRemarkHere: "输入工厂备注",
    confirmPickup: "确认顾客收货吗？",
    rolename: "角色名",
    roleid: "角色ID",
    addProduct: "添加产品",
    size: "尺寸",
    operationfactoryLogin: "工厂人员登录",
    save: '保存',
    clear: '清除',
    submit: '提交',
    StoreReasons: '门店原因',
    FactoryReasons: '工厂原因',
    Import: "导入",
    TextLengthIsTooLong: '输入文本长度限制为120字数',
    ConfirmToSend: '确认发送',
    Check: '检查',
    pleaseCheckTrackNumber: '请先检查追踪号能否发送到NAV？',
    PurchaseDate: "购买日期",
    WarrantyType: "是否保修期内",
    RequestWaivedFee: '是否免除费用',
    WaivedFee: '免除费用',
    confirmWaivedFee: '请在“门店备注“里填写原因。你将会在未来数天收到电邮关于是次申请的结果。',
    customerSign: '客户签名',
    systemManagement: "系统管理",
    systemConfig: "系统配置",
    orderClear: "订单清理",
    subReasonManage: "子原因管理",
    imageMandatory: '请上传照片',
    goldWeightImageMandatory: '请上传黄金称重照片',
    more: '更多',
    yes: '是',
    no: '否',
    other: '其他',
    OriginalProduceFactory: '生产工厂',
    QualityIssue: '质量问题'

  },
  ExpressDelivery: {
    ExpressDeliveryCompany: '快递公司',
    PleaseSelectExpressDeliveryCompany: '请选择快递公司',
    PleaseCheckRMAID: '请检查RMAID是否已填',
    PleaseCheckTrackingNumber: '请检查快递单号是否已填',
    PleaseCheckExpressDelivery: '请检查快递公司是否已填'
  },
  navbar: {
    logOut: '退出登录',
    dashboard: '首页',
    github: '项目地址',
    theme: '换肤',
    size: '布局大小',
    profile: '轮廓',
  },
  login: {
    title: '系统登录',
    logIn: '登录',
    waiting: "等待中",
    username: '账号',
    password: '密码',
    pleaseEnterTheEmail: "请您输入邮箱",
    pleaseEnterThePassword: "请您输入密码",
    pleaseEnterTheCaptcha: "请您输入验证码",
    any: '随便填',
    thirdparty: '第三方登录',
    thirdpartyTips: 
      '本地不能模拟，请结合自己业务进行模拟！！！',
    email: "邮箱",
    'username-placeholder':'账号',
    'email-placeholder':'电邮',
    'password-placeholder':'密码',
    "captcha-placeholder": "验证码",
    tryToLoginTooMany: '您已被暂缓登录，请稍后再试',
  },
  documentation: {
    documentation: '文档',
    laravel: "Laravel",
    github: 'Github 地址',
  },
  permission: {
    addRole: '新增角色',
    submit: '提交',
    editPermission: '编辑权限',
    roles: '你的权限',
    switchRoles: '切换权限',
    tips: 
      '在某些情况下，不适合使用 v-permission。例如：Element-UI 的 Tab 组件或 el-table-column 以及其它动态渲染 dom 的场景。你只能通过手动设置 v-if 来实现。',
    delete: '删除',
    confirm: '确定',
    cancel: '取消',
  },
  components: {
    documentation: '文档',
    tinymceTips: 
      '富文本是管理后台一个核心的功能，但同时又是一个有很多坑的地方。在选择富文本的过程中我也走了不少的弯路，市面上常见的富文本都基本用过了，最终权衡了一下选择了Tinymce。更详细的富文本比较和介绍见',
    dropzoneTips: 
      '由于我司业务有特殊需求，而且要传七牛 所以没用第三方，选择了自己封装。代码非常的简单，具体代码你可以在这里看到 @/components/Dropzone',
    stickyTips: 
      '当页面滚动到预设的位置会吸附在顶部',
    backToTopTips1: 
      '页面滚动到指定位置会在右下角出现返回顶部按钮',
    backToTopTips2: 
      '可自定义按钮的样式、show/hide、出现的高度、返回的位置 如需文字提示，可在外部使用Element的el-tooltip元素',
    imageUploadTips: 
      '由于我在使用时它只有vue@1版本，而且和mockjs不兼容，所以自己改造了一下，如果大家要使用的话，优先还是使用官方版本。',
  },
  table: {
    description: "描述",
    dynamicTips1: '固定表头, 按照表头顺序排序',
    dynamicTips2: '不固定表头, 按照点击顺序排序',
    dragTips1: '默认顺序',
    dragTips2: '拖拽后顺序',
    name: "姓名",
    title: '标题',
    importance: '重要性',
    type: '类型',
    remark: '点评',
    search: '搜索',
    add: '添加',
    export: '导出',
    reviewer: '审核人',
    id: '序号',
    date: '时间',
    author: '作者',
    readings: '阅读数',
    status: '状态',
    actions: '操作',
    edit: '编辑',
    publish: '发布',
    draft: '草稿',
    delete: '删除',
    cancel: '取 消',
    confirm: '确 定',
    keyword: "Keyword",
    role: "Role",
  },
  errorLog: {
    tips: '请点击右上角bug小图标',
    description: 
      '现在的管理后台基本都是spa的形式了，它增强了用户体验，但同时也会增加页面出问题的可能性，可能一个小小的疏忽就导致整个页面的死锁。好在 Vue 官网提供了一个方法来捕获处理异常，你可以在其中进行错误处理或者异常上报。',
    documentation: '文档介绍',
  },
  listRMA: {
    sku: "产品",
    printLabel: "打印标签",
    printPackageList: "打印装箱单",
    reset: "复位",
    placeholder: "请输入文件名(默认excel-list)",
    printPackageListNow: "点击打印装箱单",
    waivedFeeStatus_0: "否",
    waivedFeeStatus_1: "审批中",
    waivedFeeStatus_10: "拒绝",
    waivedFeeStatus_11: "批准",
    resetAll: "全部复位",
    rejectReasonTitle: "你确认拒绝这张订单吗?",
    pleaseSelectRejectReason: "请选择拒绝原因"
  },
  productDetail: {
    orderNumber: "订购单号",
    orderID: "订购序号",
    Date: "订购时间",
    RepairFee: "维修金额",
    InvoiceNumber: "发票号码",
    SequenceNo: "序列号",
    Grams: "（克）",
    goldProductInfo: "黄金饰品信息",
    GoldWeight: "黄金重量",
    CaratWeight: "钻石重量",
    Carat: "（克拉）",
    totalWeight: "总重",
    fakesInfo: '假货信息',
    fakesInfoImage: '假货图片',
    fakesInfoRemark: '假货备注',
    fakesInfoUpload: '鉴定上传',
    uploadFakesInfo: '上传图片',
    remark: '备注',
    pleaseUploadImage: '请上传图片',
    pleaseInputRemark: '请填写备注',
    HavePuchaseRecord: '购买记录',
    yesPuchaseRecord: '有',
    noPuchaseRecord: '无',
    edit: '修改',
    editTrackingNumberModal: '修改快递单号',
    newTrackingNumber: '快递单号',
    pleaseInputNewTrackingNumber: '请输入新的快递单号',
    Delivery: '快递公司',
    pleaseSelectDelivery: '请选择快递公司',
    ContactUsByPhone: '电话联系',
    ContactUsByWhatsapp: 'WhatsApp联系',
    undo: '上一步',
    pickupFollowup: '售后跟踪',
    contactLabel: '联系顾客',
    pleaseSelectContactResult: '请选择联系结果',
    "Phone: Contacted": '电话: 已联系',
    "Phone: No answer": '电话: 未应答',
    "Whatsapp: Reminder Sent": 'WhatsApp: 已发送提醒',
    "Wechat: Reminder Sent": '微信: 已发送提醒',
    "Email : Reminder Sent": '邮件: 已发送提醒',
    EmptyFollowUpList: '您尚未联系客户，请联系客户并记录结果。',
    ShippingFee: '运输费用'
  },
  excel: {
    export: '导出',
    selectedExport: '导出已选择项',
    placeholder: '请输入文件名(默认excel-list)',
  },
  zip: {
    export: '导出',
    placeholder: '请输入文件名(默认file)',
  },
  pdf: {
    tips: 
      '这里使用 window.print() 来实现下载pdf的功能',
  },
  theme: {
    change: '换肤',
    documentation: '换肤文档',
    tips: 
      'Tips: 它区别于 navbar 上的 theme-pick, 是两种不同的换肤方法，各自有不同的应用场景，具体请参考文档。',
  },
  tagsView: {
    refresh: '刷新',
    close: '关闭',
    closeOthers: '关闭其它',
    closeAll: '关闭所有',
  },
  settings: {
    title: "Page style setting",
    theme: "Theme Color",
    tagsView: "Open Tags-View",
    fixedHeader: "Fixed Header",
    sidebarLogo: "Sidebar Logo",
  },
  user: {
    role: "角色",
    password: "密码",
    confirmPassword: "确认密码",
    name: "姓名",
    email: "邮箱",
  },
  roles: {
    description: {
      admin:
        "Super Administrator. Have access and full permission to all pages.",
      manager:
        "Manager. Have access and permission to most of pages except permission page.",
      editor:
        "Editor. Have access to most of pages, full permission with articles and related resources.",
      user: "Normal user. Have access to some pages",
      visitor:
        "Visitor. Have access to static pages, should not have any writable permission",
    },
  },
  SAVCheck: {
    SAVCheckResult: "SAV来货检查",
    TrackingNumber: "追踪号",
    WrongReasonNumber: "错误理由件数",
    noWarrantyNumber: "已过保修期件数",
    enterTrackingNumber: "请输入追踪号",
    enterWrongReasonNumber: "请输入错误理由件数",
    enterNoWarrantyNumber: "请输入已过保修期件数",
    enterNumber: "请输入数字",
    errorTitle: "错误提示",
    errorWrongReasonNumber: "错误理由件数必须是数字",
    errorNoWarrantyNumber: "已过保修期件数必须是数字",
    errorMessage: "SAV来货检查失败，请重试",
    "-401002": "输入参数不正确，请重新输入",
    "-401003": "追踪号不存在，请重新输入",
    successTitle: "成功提示",
    successMessage: "SAV来货检查成功",
    warnTitle: "警告",
    warnCheckNumber: "小数已被取整，请注意最终结果",
    infoTitle: "温馨提示",
  },
  createRMA: {
    PleaseInputFirstName: "请输入名",
    PleaseInputLastName: "请输入姓",
    PleaseInputThePhoneCode: "请输入地区号",
    PleaseInputThePhone: "请输入电话",
    PleaseInputOnlyNumbers: "请输入纯数字电话号码",
    PleaseSelectProvince: "请选择省市区",
    PleaseSelectCountry: "请选择国家",
    PleaseSelectCity: "请选择城市",
    PleaseInputAddress: "请输入详细地址",
    PleaseInputReceiverName: "请输入接收方姓名",
    PleaseInputReceiverPhone: "请输入接收方电话",
    IncorrectPhone: '手机号码格式不正确',
    ModifyInCRM: "请到CRM系统修改完成后重试",
    PleaseInputEmail: "请输入正确邮箱",
    ProductReasonsAreMandatory : "产品原因是强制性的",
    ProductDateAreMandatory: "购买日期是强制性的",
    OriginalOrderIdAreMandatory: "原始订单 ID 为必填项",
    OriginalNumberAreMandatory: "原始订单序号为必填项",
    PleaseEnsureAllProductHaveASelectedReason: "请确保所有产品都有选定的原因",
    ProductMaintenanceAmountAreMandatory: "维修金额是强制性的",
    ProductWaivedFeeAndCommentsAreMandatory: "若申请免除费用则必须填写门店备注",
    ProductInvoiceNumberAreMandatory: "发票号码是强制性的",
    GoldProductInfoAreMandatory: "黄金饰品请先检测序列号",
    TotalWeightAreMandatory: "总重为必填项",
    Country: "国家",
    City: "城市",
    FulfilledDate: "开票日期",
    Type: "购买平台",
    Language: "语言",
    CountryIsMandatory: "国家为必填项",
    CityIsMandatory: "城市为必填项",
    FulfilledDateIsMandatory: "完成时间为必填项",
    TypeIsMandatory: "购买平台为必填项",
    LanguageIsMandatory: "语言为必填项",
    PleaseSelectAtLeastRenewReason: "请在右侧至少选择一个翻新原因",
    isNotAllowSendToCustomer: '当前国家不允许寄回给客人',
    pleaseCheckInvoiceAgain: '请重新校验发票号',
    invoiceIsNotExit: '发票号不存在',
    invoiceIsNotIncludeShippingInfo: '该发票不包含运费信息',
    checkInvoiceError: '校验发票号错误，请重试'
  },
  sysAdmin: {
    syncTS: "立即同步TypeSense",
    importProduct: "导入产品至Typesense",
    Async: '同步',
    rma_id: 'RMA ID',
    start_date: '开始日期',
    end_date: '结束日期',
    limit: '清理订单限制数量',
    valid_date_of_pruchase: '是否校验创建日期',
    clear: '清理',
    DoYouWantToClearOrder: '是否要更新这些订单状态？',
    EndDateIsRequired: '结束日期是必填的'
  },
  InvalidParameter: {
    TrackingNo: '此快递单号没有绑定任何RMA记录',
    Locked: '此快递单号已经被使用过',
    Country: '非中国外门店',
    commonSuccess: 'RMA已经成功发送至NAV',
    commonError: 'RMA发送至NAV失败',
    TrackingNoNotSent: '此快递单号未发送至NAV'
  },
  sendPackageToNAV: {
    DomesticCurrencyValue: '本币价值',
    dataNotFound: '无该快递单号数据',
    TotalQuantity: '总数量',
    TotalValue: '总价值',
    TotalWeight: '总重量',
    sendPlaceSelect: '选择寄往',
    sendToCN: '寄到中国工厂',
    sendToMC: '寄到摩纳哥工厂',
  },
  customerStatus:{
    title: 'APM Monaco维修申请',
    apmSale: '售后服务',
    customerName: '客户姓名',
    shopName: '店铺',
    shopPhone: '店铺电话',
    shopEmail: '地址邮箱',
    created: '已创建',
    productReference: '参考编号',
    repairFee: '维修费用',
    repairReason: '维修原因',
    clickText: '点击此处查看您的维修进度',
    enjoyText: '在www.apm.mc享受购物',
    tipsText: '我们每天 24 小时, 每周七天为您提供服务',
    step1Title: '步骤一',
    step2Title: '步骤二',
    step3Title: '步骤三',
    step4Title: '步骤四',
    step5Title: '步骤五',
    step6Title: '步骤六',
    step1Content: '存放在APM店铺',
    step2Content: '运送至维修中心',
    step3Content: '维修中心',
    step4Content: '运送至APM店铺',
    step5Content: '可取货',
    step6Content: '已取货',
    orderID: '订单编号',
    orderName: '订单名称',
    trackingNumber: '追踪号',
    userName:'姓名',
    userEmail: '电子邮箱',
    userPhone: '电话',
  },
  transferFactory: {
    clickImportButton: '点击导入按钮保存数据',
    cannotClickBecauseBlankTracking: "数据为空时无法点击",
    dataIsSaved: '数据已经保存至列表'
  },
  captcha: {
    captchaError: "获取验证码失败，请刷新重试",
    updateCaptchaLater: "验证码更新过于频繁"
  },
  reasonManage: {
    subReason: '子原因',
    name: '原因名称',
    IsActive: '是否激活',
    Active: '激活',
    InActive: '未激活',
    IsWarranty: '是否保修',
    sort: '排序',
    CreatedBy: '创建人',
    addSubReason: '添加子原因',
    editSubReason: '编辑子原因',
    SubReasonBy: '子原因归属',
    SubReasonName: '子原因名称',
    SubReasonDesc: '子原因描述',
    SubReasonNameIsRequired: '子原因名称不能为空',
    SubReasonIsupdated: '子原因已更新',
    pleaseSelectSubReason: '请选择子原因',
  },
  reasonsName: {
    "InvalidOrder(Duplicate)": '无效单据 (重复)',
    "NotAPMProduct": '非APM产品',
    "InconsistentwithSAVPolicy": '不符合售后政策',
    "LockDefective": '锁扣损坏',
    "WrongProduct": '产品错误',
    Renew: "翻新",
    RenewTarnished: '翻新: 氧化',
    RenewStoneMissing: '翻新: 锆石或其它镶嵌物料缺失',
    RenewPearlMissing: '翻新: 缺失珍珠',
    RenewDefectiveLock: '翻新: 残次锁扣',
    RenewBroken: '翻新: 损坏',
    RenewDeformed: '翻新: 变形',
    RenewColorFadingUnderWarranty: '翻新: 褪色',
    RenewResizing: '翻新: 调整大小',
    RenewEarringPinIssue: '翻新: 耳针问题',
    Tarnished: "氧化",
    "Pen-platingTarnished": '笔电位氧化',
    "NormalPlatingTarnished": '非笔电位氧化',
    StoneMissing: "锆石或其它镶嵌物料缺失",
    "NeedChangeStone(DirtyorCrack)": '换石（石头脏花等）',
    PearlMissing: "缺失珍珠",
    NacreMissing: "掉贝母类",
    Scratched: "划痕",
    ComponentMissing: "配件缺失",
    MainPartMissing: "主件缺失",
    DefectiveLock: "残次锁扣",
    SliderDefect: "猪鼻拉扣松",
    EarringLockDefect: "耳环闭合卡扣松",
    "Bracelet&BangleLockDefect": "手镯手链等闭合卡扣松",
    "Bangle&EarringHingeJointDefect": "手镯、耳环较位摇摆",
    EarPinCrack: "耳针断裂",
    Broken: "损坏",
    ChainBroken: "链条断裂",
    WeldingPointBroken: "焊点断裂",
    EarringPinBroken: "耳针断裂",
    MainPartBroken: "主件银块断裂",
    Deformed : "变形",
    WebsiteOnlineRefund: "网上退款",
    ColorFading: "褪色",
    ColorFadingUnderWarranty: "褪色",
    AbrasionAfterUsed: "使用磨损",
    ColorFadingAfterUsed: "使用褪色",
    AbnormalPeelOff: "产品镀层掉皮",
    Resurface: "Resurface",
    OptionalComments: "其他问题",
    BrokenByClient: "客户损坏",
    DeformedByClient: "客户弄变形的",
    Resizing: '调整大小',
    EarringPinIssue: '耳针问题',
    sub: '子原因:'
  },
  QualityInvestigate: {
    OriginalProduceFactory: "生产工厂",
    QualityInvestigate: "品质调查",
    ExpireWarranty: "超出保修期",
    SAVRepairIssue: "售后维修问题",
    ProductionIssue: "生产问题",
    DesignIssue: "设计问题",
    ComponentsIssue: "配件问题",
    CustomersIssue: "顾客问题",
  }
};
